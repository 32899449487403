html,
body,
#root {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0b0c;
}

body {
  overflow: hidden;
  margin: 0; /* Remove the default margin on the body */
}

a {
  color: lightblue !important;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #0a0b0c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fade-in {
  transition: opacity 4s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 4s ease;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 10px; /* Negative margin to counteract the margin on .gallery-item */
  margin-right: 100px;
  margin-top: 0px; /* Negative margin to counteract the margin on .gallery-item */
}

.gallery-item {
  flex: 0 0 calc(25% - 10px);
  margin: 5px;
  padding-bottom: calc(10% * 0.03); /* Maintain the 4x3 aspect ratio (75% of the width) */
  box-sizing: border-box;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* For smaller screens, display three images per row */
@media (max-width: 900px) {
  .gallery-item {
    flex: 0 0 calc(33.333% - 10px);
  }
}

/* For even smaller screens, display two images per row */
@media (max-width: 600px) {
  .gallery-item {
    flex: 0 0 calc(50% - 10px);
  }
}

.hidden {
  display: none;
}

.home {
  display: flex;
  height: 100vh; /* Ensure the .home container fills the entire viewport height */
  overflow: hidden; /* Prevent scrolling within the .home container */
}

.left-column {
  flex: 1; /* Let the left column grow to fill the available space */
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent scrolling within the left column */
  margin-bottom: 0; /* Remove extra space at the bottom */
}

.links {
  flex-grow: 0; /* Prevent links from growing */
}

.links a {
  margin-left: 30px;
  margin-top: 80px;
  margin-bottom: 80px;
  color: lightblue;
  font-size: calc(10px + 2vmin + 10px); /* Increase the font size by 10 pixels */
}

.model {
  width: 100%;
  height: 100%;
}

.model-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 50vw; /* Increase the width as desired */
  height: 50vw; /* Maintain a square aspect ratio */
  z-index: 2; /* Ensure it is positioned above other content */
}

.navigation {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: none;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  z-index: 999;
  color: lightblue;
}

.navigation ul {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.navigation li {
  margin: 0 10px;
}

.navigation li a {
  color: lightblue;
  text-decoration: none;
}

.navigation li a:hover {
  text-decoration: underline;
}


.navigation .standalone-button {
  background: none;
  border: none;
  padding: 0;
  color: lightblue;
}

.navigation .standalone-button:hover {
  color: lightblue;
}

.overlay {
  position: absolute;
  top: 10%;
  left: 28.5%;
  width: 50%;
  transform: translate3d(-30%, -50%, 0);
  font-size: 2em;
  color: rgb(0, 255, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay-item {
  margin-bottom: 1em;
}

.overlay-item a {
  text-decoration: none;
  color: inherit;
}

.right-column {
  flex: 1; /* Let the right column grow to fill the available space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.typewriter-container {
  display: flex;
  justify-content: left;
  margin-left: 30px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: limegreen;
  font-family: sans-serif;
}

.wrapper {
  margin-top: 35px; /* Adjust this value as needed */
  min-height: calc(100vh - 100px); /* Adjust this value as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

